import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import globalSettings from "./settings";
import { BacktestParameterForm } from "./backtestParameters";

function BacktestSetttingsJobModal() {
  const [showModal, setShowModal] = useState(false);
  const [parameterValues, setParameterValues] = useState({});

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = () => {
    fetchSettingsData();
    setShowModal(true);
  };

  const fetchSettingsData = () => {
    fetch(`${globalSettings.host}/get-settings?job_id=live_settings`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch settings data");
        }
      })
      .then((data) => {
        setParameterValues(data);
      })
      .catch((error) => {
        console.error("Error fetching settings data:", error);
      });
  };

  const handleConfirm = () => {
    fetch(`${globalSettings.host}/save-live-backtest-settings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(parameterValues),
    })
      .then((response) => {
        if (response.ok) {
          alert("Settings saved");
        } else {
          alert("Error posting data:", response.status, response.statusText);
        }
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
    handleClose();
  };

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Settings
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BacktestParameterForm
            includeDateParams={false}
            setParameterValues={setParameterValues}
            parameterValues={parameterValues}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BacktestSetttingsJobModal;
