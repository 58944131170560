import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useEffect } from "react";
import Chart from "./chart";
import Button from "react-bootstrap/Button";
import globalSettings from "./settings";
import Spinner from "react-bootstrap/Spinner";
import { BacktestParameterForm, validateForm } from "./backtestParameters";
import SingleContractBacktestResults from "./singleConractBacktestResults";

function SingleContractTab({ tabClicked }) {
  const [parameterValues, setParameterValues] = useState({});
  const [refreshChart, setRefreshChart] = useState(null);
  const [backtestResultsData, setBacktestResultsData] = useState({});
  const [backtestLoading, setBacktestLoading] = useState(false);
  const [showDataButtonVisible, setShowDataButtonVisible] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const [deliveryStart, setDeliveryStart] = useState(null);

  useEffect(() => {
    setRefreshChart((prev) => prev + 1);
  }, [tabClicked]);

  const handleDatetimeInputChange = (event) => {
    setParameterValues((prev) => ({
      ...prev,
      deliveryStart: event.target.value,
    }));

    setShowDataButtonVisible(true);
  };

  const handleShowData = () => {
    setBacktestResultsData({});
    setDeliveryStart(parameterValues.deliveryStart);
    setShowChart(true);
  };

  const handleConfirm = () => {
    setBacktestLoading(true);
    fetch(`${globalSettings.host}/backtest-single-contract`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(parameterValues),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        setBacktestLoading(false);
        setRefreshChart((prevValue) => prevValue + 1);
        setBacktestResultsData(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const formIsValidated = validateForm(parameterValues);

  return (
    <div>
      <Row>
        <Col sm="6">
          <Form.Group
            as={Row}
            controlId="datetime"
            className="mb-2"
            key="date"
            style={{ width: "100%" }}
          >
            <Form.Label column>Delivery start</Form.Label>
            <Col sm="5">
              <Form.Control
                type="datetime-local"
                value={parameterValues["deliveryStart"]}
                onChange={handleDatetimeInputChange}
              />
            </Col>
          </Form.Group>
          <div className="text-end  mt-3 me-4">
            <Button
              disabled={!showDataButtonVisible}
              variant="primary"
              onClick={handleShowData}
            >
              Show data
            </Button>
          </div>
        </Col>
        <Col sm="6">
          <BacktestParameterForm
            setParameterValues={setParameterValues}
            parameterValues={parameterValues}
            includeDateParams={false}
          />
          <div className="text-end  mt-4 mb-2">
            <Button
              variant="primary"
              onClick={handleConfirm}
              disabled={!formIsValidated}
            >
              {backtestLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              Test
            </Button>
          </div>
        </Col>
      </Row>
      <hr />
      {Object.keys(backtestResultsData).length === 0 ? null : (
        <SingleContractBacktestResults
          backtestResultsData={backtestResultsData}
        />
      )}
      <div style={{ width: "100%" }}>
        {showChart && (
          <Chart
            deliveryStart={deliveryStart}
            jobId={"single_contract_settings"}
            refreshChart={refreshChart}
          />
        )}
      </div>
    </div>
  );
}

export default SingleContractTab;
