import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import Badge from "react-bootstrap/Badge"; // Import Badge component
import globalSettings from "../settings";

function PositionSuggestionsTable({ randomNumber, setSelectedDeliveryStart }) {
  const [signalData, setSignalData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${globalSettings.host}/get-latest-signals`)
      .then((response) => response.json())
      .then((data) => {
        setSignalData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, [randomNumber]);

  // Function to calculate background color based on Total Imbalance NL value
  const calculateBackgroundColor = (value) => {
    // Normalize the value to be between -1000 and 1000
    const normalizedValue = Math.min(Math.max(value, -2000), 2000);
    // Calculate opacity based on normalized value
    const opacity = Math.abs(normalizedValue) / 2000;
    // Interpolate the color with Bootstrap's green and red colors based on the sign of the value
    return normalizedValue >= 0
      ? `rgba(40, 167, 69, ${opacity})`
      : `rgba(220, 53, 69, ${opacity})`;
  };

  const handleRowClick = (deliveryStart) => {
    setSelectedDeliveryStart(deliveryStart);
  };

  return (
    <div style={{ position: "relative", height: "100%" }}>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="sr-only"></span>
          </Spinner>
        </div>
      )}
      <Table bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Delivery Start</th>
            <th>Wind Imbalance NL</th>
            <th>Solar Imbalance NL</th>
            <th>Total Imbalance NL</th>
            <th>Previous Signal</th>
            <th>Latest Signal</th>
            <th>Changed</th>
          </tr>
        </thead>
        <tbody>
          {signalData.map((row, index) => {
            const shouldHighlight = Math.abs(row.delta) !== 0;

            return (
              <tr
                key={index}
                className={shouldHighlight ? "table-active" : ""}
                onClick={() => handleRowClick(row.delivery_start)}
                role="button"
              >
                <td>{row.delivery_start}</td>
                <td>{row.wind_imbalance_nl}</td>
                <td>{row.solar_imbalance_nl}</td>
                <td
                  style={{
                    backgroundColor: calculateBackgroundColor(
                      row.total_imbalance_nl
                    ),
                  }}
                >
                  {row.total_imbalance_nl}
                </td>
                <td>{row.previous_signal}</td>
                <td>{row.latest_signal}</td>
                <td>{shouldHighlight && <Badge bg="secondary">New</Badge>}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default PositionSuggestionsTable;
