import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import globalSettings from "./settings";
import { Gear } from "react-bootstrap-icons";

function formatString(inputString) {
  // Split the input string by underscores
  const words = inputString.split("_");

  // Capitalize the first letter of each word and join them with spaces
  const formattedString = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedString;
}

function convertValueToString(value) {
  // Convert boolean values to text
  if (typeof value === "boolean") {
    return value ? "True" : "False";
  }
  return value;
}

function ShowSettings({ jobId }) {
  const [showModal, setShowModal] = useState(false);
  const [settings, setSettings] = useState({});

  const handleClose = () => {
    setShowModal(false);
  };
  const handleShow = () => {
    fetch(`${globalSettings.host}/get-settings?job_id=${jobId}`)
      .then((response) => response.json())
      .then((data) => {
        const convertedSettings = {};
        for (const key in data) {
          convertedSettings[key] = convertValueToString(data[key]);
        }
        setSettings(convertedSettings);
      })
      .catch((error) => console.error(error));
    setShowModal(true);
  };

  return (
    <div>
      <Gear className="m-1" onClick={handleShow} role="button" />

      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table>
            <tbody>
              {Object.entries(settings).map(([key, value]) => (
                <tr key={key}>
                  <td style={{ width: "45%" }}>{formatString(key)}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ShowSettings;
