import { React } from "react";
import Plot from "react-plotly.js";

const WeatherChart = ({ data, country, handlePlotClick }) => {
  const solarImbalanceGraph = {
    x: data.map((item) => item.delivery_start),
    y: data.map((item) => item[`solar_imbalance_${country}`]),
    type: "bar",
    xaxis: "x",
    yaxis: "y",
    name: `Solar Imbalance ${country.toUpperCase()}`,
    marker: {
      color: "#ffc107", // Bootstrap yellow color
    },
  };
  const windImbalanceGraph = {
    x: data.map((item) => item.delivery_start),
    y: data.map((item) => item[`wind_imbalance_${country}`]),
    type: "bar",
    xaxis: "x",
    yaxis: "y",
    name: `Wind Imbalance ${country.toUpperCase()}`,
    marker: {
      color: "#F05A28", // Grafana orange color
    },
  };
  const currentHour = new Date();

  return (
    <Plot
      onClick={handlePlotClick}
      data={[solarImbalanceGraph, windImbalanceGraph]}
      layout={{
        title: {
          text: `${country.toUpperCase()}`,
          x: 0.01,
          y: 0.97,
          xanchor: "left",
          yanchor: "top",
          font: {
            size: 16,
            color: "#c8c8c8",
          },
        },
        shapes: [
          {
            type: "line",
            xref: "x",
            yref: "paper",
            x0: currentHour,
            y0: 0,
            x1: currentHour,
            y1: 1,
            line: {
              color: "grey",
              width: 2,
              dash: "dot",
            },
          },
        ],
        autosize: true,
        plot_bgcolor: "rgba(0, 0, 0, 0)",
        paper_bgcolor: "rgba(0, 0, 0, 0)",
        font: { color: "#c8c8c8" },
        colorway: [
          "#f44336",
          "#e91e63",
          "#3f51b5",
          "#009688",
          "#ffc107",
          "#ff9800",
          "#03a9f4",
        ],
        hoverlabel: {
          bgcolor: "black",
        },
        xaxis: {
          gridcolor: "#3a3a3a",
          rangeslider: { visible: false },
          tickmode: "auto", // Show all x-ticks
          nticks: 80,
          tickformat: "%H", // Show only the hour
        },
        yaxis: { gridcolor: "#3a3a3a" },
        margin: { t: 40, r: 0, b: 40, l: 30 },
        legend: {
          orientation: "h", // Horizontal orientation
          x: 0, // Center the legend horizontally
          y: -0.15, // Position the legend below the plot
        },
      }}
      style={{ height: 400, width: "50%" }}
      useResizeHandler={true}
    />
  );
};
export default WeatherChart;
