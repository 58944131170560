import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import ShowSettings from "./showSettings";
import Information from "./tooltip";

const SettingsTable = ({ onRowSelect, resultsData }) => {
  const [selectedRow, setSelectedRow] = useState(null);

  return resultsData.length === 0 ? (
    <p>No backtest settings found. Click on 'New Job' to do a backtest</p>
  ) : (
    <Table bordered hover size="sm">
      <thead>
        <tr>
          <th>Job Id</th>

          <th>Entries</th>
          <th>Wins</th>
          <th>Losses</th>
          <th>
            Sig. Losses <Information info={"Cases with more than €1000 lost"} />{" "}
          </th>
          <th>Imbalance</th>
          <th>
            Fee <Information info={"13 ct + 0.15ct per MW"} />
          </th>
          <th>P&L per day</th>
          <th>
            P&L total{" "}
            <Information
              info={"Fees have been deducted. Unclosed positions are excluded"}
            />
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {resultsData.map((row, index) => (
          <tr
            key={index}
            onClick={() => {
              onRowSelect(row.job_id);
              setSelectedRow(row.job_id);
            }}
            className={row.job_id === selectedRow ? "table-active" : null}
            role="button"
          >
            <td>{row.job_id}</td>

            <td>{row.entries}</td>
            <td>{row.wins}</td>
            <td>
              {row.loses} ({((row.loses / row.entries) * 100).toFixed(0)}%)
            </td>
            <td>
              {row.sig_loses} (
              {((row.sig_loses / row.entries) * 100).toFixed(0)}%)
            </td>
            <td>
              {row.total_imbalance} (+{row.positive_imbalance}{" "}
              {row.negative_imbalance})
            </td>
            <td>€ {Number(row.fee).toLocaleString()}</td>
            <td
              className={row.pnl_per_day > 0 ? "text-success" : "text-danger"}
            >
              € {Number(row.pnl_per_day).toLocaleString()}
            </td>
            <td className={row.net_profit > 0 ? "text-success" : "text-danger"}>
              € {Number(row.net_profit).toLocaleString()}
            </td>
            <td>
              <ShowSettings jobId={row.job_id} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SettingsTable;
