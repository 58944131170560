import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import globalSettings from "./settings";

const ExportTab = (data) => {
  const [periodStart, setPeriodStart] = useState("");
  const [periodEnd, setPeriodEnd] = useState("");

  const fetchData = () => {
    const apiUrl = `${globalSettings.host}/export-data?period_start=${periodStart}&period_end=${periodEnd}`;

    fetch(apiUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `data${periodStart}_${periodEnd}.csv`;
        a.click();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div>
      <Form.Group
        as={Row}
        className="mb-2"
        key="period_start"
        style={{ width: 400 }}
      >
        <Form.Label column>Period start</Form.Label>
        <Col sm="5">
          <Form.Control
            type="date"
            value={periodStart || ""}
            onChange={(e) => setPeriodStart(e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group
        as={Row}
        className="mb-2"
        key="period_end"
        style={{ width: 400 }}
      >
        <Form.Label column>Period end</Form.Label>
        <Col sm="5">
          <Form.Control
            type="date"
            value={periodEnd || ""}
            onChange={(e) => setPeriodEnd(e.target.value)}
          />
        </Col>
      </Form.Group>
      <Button variant="primary" onClick={fetchData}>
        Export data
      </Button>{" "}
    </div>
  );
};

export default ExportTab;
