import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import globalSettings from "./settings";
import { validateForm, BacktestParameterForm } from "./backtestParameters.js";

function NewJobModal({ jobsCount }) {
  const [showModal, setShowModal] = useState(false);
  const [parameterValues, setParameterValues] = useState({});
  const [submitEnabled, setSubmitEnabled] = useState(false);

  useEffect(() => {
    validateForm(parameterValues)
      ? setSubmitEnabled(true)
      : setSubmitEnabled(false);
  }, [parameterValues]);

  const handleClose = () => {
    setShowModal(false);
  };
  const handleShow = () => {
    setShowModal(true);
  };

  const handleConfirm = () => {
    fetch(`${globalSettings.host}/new-job`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(parameterValues),
    })
      .then((response) => {
        if (response.ok) {
          alert("New job submitted");
          window.location.reload();
        } else {
          console.error(
            "Error posting data:",
            response.status,
            response.statusText
          );
        }
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
    handleClose();
  };

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        New Job
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New backtest job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BacktestParameterForm
            setParameterValues={setParameterValues}
            parameterValues={parameterValues}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirm}
            disabled={!submitEnabled}
          >
            Run backtest
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NewJobModal;
