import Table from "react-bootstrap/Table";

function DeliveryStatsTable({
  selectedDeliveryStart,
  setSelectedDeliveryStart,
  deliveryStatsData,
}) {
  return (
    <div style={{ height: "300px", overflow: "auto" }}>
      <Table bordered hover size="sm">
        <thead>
          <tr>
            <th>Delivery Start</th>
            <th>VWAP Buy</th>
            <th>VWAP Sell</th>
            <th>Max. Abs. Position</th>
            <th>Total trades MW</th>
            <th>Imbalance</th>
            <th>P&L</th>
          </tr>
        </thead>
        <tbody>
          {deliveryStatsData.map((row, index) => (
            <tr
              key={index}
              onClick={() => {
                setSelectedDeliveryStart(row);
              }}
              className={
                row.delivery_start === selectedDeliveryStart
                  ? "table-active"
                  : null
              }
              role="button"
            >
              <td>{row.delivery_start}</td>
              <td>{`€ ${row.vwap_buy}`}</td>
              <td>{`€ ${row.vwap_sell}`}</td>
              <td>{row.max_abs_position} MW</td>
              <td>{row.total_trade_quantity} MW</td>
              <td>{row.net_position} MW</td>
              <td
                className={row.pnl < 0 ? "text-danger" : "text-success"}
              >{`€ ${Number(row.pnl).toLocaleString()}`}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default DeliveryStatsTable;
