import React, { useState, useEffect } from "react";
import BacktestSetttingsJobModal from "../backtestSettingsJobModal";
import PositionSuggestionsTable from "./PositionSuggestionsTable";
import LiveRunsTable from "./LiveRunsTable";
import { ArrowClockwise } from "react-bootstrap-icons";
import { Button, Form, Modal } from "react-bootstrap"; // Import Modal component
import WeatherChart from "./weatherChart";
import globalSettings from "../settings";
import Spinner from "react-bootstrap/Spinner";
import Chart from "../chart";

const NotificationsTab = () => {
  const [randomNumber, setRandomNumber] = useState(0);
  const [weatherData, setWeatherData] = useState([]);
  const [latestRun, setLatestRun] = useState(null);
  const [timeDifference, setTimeDifference] = useState(null);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [chartDataIsLoading, setChartDataIsLoading] = useState(false);
  const [selectedDeliveryStart, setSelectedDeliveryStart] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility

  const fetchData = () => {
    setChartDataIsLoading(true);
    fetch(`${globalSettings.host}/get-latest-weather-imbalance`)
      .then((response) => response.json())
      .then((data) => {
        setWeatherData(data);
        setChartDataIsLoading(false);
      })
      .catch((err) => setChartDataIsLoading(false));
  };

  useEffect(() => {
    fetchData();
    const intervalId = autoRefresh ? setInterval(fetchData, 30000) : null;

    // Clean up function
    return () => {
      clearInterval(intervalId);
    };
  }, [autoRefresh, randomNumber]);

  useEffect(() => {
    const intervalId = autoRefresh
      ? setInterval(() => {
          setRandomNumber((prevNumber) => prevNumber + 1);
        }, 30000)
      : null;

    // Clean up function
    return () => {
      clearInterval(intervalId);
    };
  }, [autoRefresh]);

  const handlePlotClick = (data) => {
    if (data.points.length > 0) {
      const selectedPoint = data.points[0];
      setSelectedDeliveryStart(selectedPoint.x);
    }
  };
  useEffect(() => {
    if (selectedDeliveryStart !== null) {
      setShowModal(true);
    }
  }, [selectedDeliveryStart]);

  useEffect(() => {
    if (latestRun) {
      const currentTime = new Date();
      const latestRunTime = new Date(latestRun.finished_timestamp);
      const differenceInMs = currentTime - latestRunTime;
      const differenceInMinutes = Math.round(differenceInMs / (1000 * 60));
      setTimeDifference(differenceInMinutes);
    }
  }, [latestRun, randomNumber]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          marginBottom: "5px",
        }}
      >
        <h5 style={{ marginRight: "auto", marginBottom: "0px" }}>
          Imbalance Overview
        </h5>
        <p
          style={{ marginRight: "10px", marginTop: "0px", marginBottom: "0px" }}
        >
          Latest algo run:{" "}
          {timeDifference !== null ? `${timeDifference} minutes ago` : ""}
        </p>

        <Form.Check
          type="switch"
          id="auto-refresh-switch"
          label="Auto refresh"
          checked={autoRefresh}
          onChange={() => setAutoRefresh(!autoRefresh)}
          className="me-2 ms-2"
          style={{ marginBottom: "0px" }}
        />
        <Button
          variant="secondary"
          className="ms-2 me-2"
          onClick={() => setRandomNumber((prevNumber) => prevNumber + 1)}
        >
          <ArrowClockwise />
        </Button>
        <BacktestSetttingsJobModal />
      </div>

      <div style={{ display: "flex", position: "relative" }}>
        <WeatherChart
          data={weatherData}
          country="nl"
          handlePlotClick={handlePlotClick}
        />
        <WeatherChart
          data={weatherData}
          country="de"
          handlePlotClick={handlePlotClick}
        />

        {chartDataIsLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              zIndex: 9999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only"></span>
            </Spinner>
          </div>
        )}

        {/* Modal component */}
        <Modal
          show={showModal}
          onHide={() => {
            setShowModal(false);
            setSelectedDeliveryStart(null);
          }}
          dialogClassName="modal-xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Chart for {selectedDeliveryStart}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Pass selected tick data to Chart component */}
            {selectedDeliveryStart && (
              <Chart
                deliveryStart={selectedDeliveryStart}
                realtime={true}
                jobId={latestRun.job_id}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowModal(false);
                setSelectedDeliveryStart(null);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <br />
      <h5>Position Suggestions</h5>
      <PositionSuggestionsTable
        randomNumber={randomNumber}
        setSelectedDeliveryStart={setSelectedDeliveryStart}
      />
      <br />
      <h5>Live runs</h5>
      <LiveRunsTable randomNumber={randomNumber} setLatestRun={setLatestRun} />
      <br />
      <br />
      <br />
    </div>
  );
};

export default NotificationsTab;
