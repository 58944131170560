import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import globalSettings from "./settings";

const DailyChart = ({ jobId }) => {
  const [graphObject, setGraphObject] = React.useState({});

  useEffect(() => {
    fetch(`${globalSettings.host}/get-daily-stats?job_id=${jobId}`)
      .then((response) => response.json())
      .then((data) => {
        const netProfitGraph = {
          x: data.map((item) => item.delivery_day),
          y: data.map((item) => item.net_profit),
          type: "bar",
          xaxis: "x",
          yaxis: "2",
          name: "Net Profit",
          marker: {
            color: "#dc3545", // Bootstrap red color
          },
        };
        setGraphObject((prev) => ({ ...prev, netProfitGraph }));
      });
  }, [jobId]);

  return (
    <Plot
      data={Object.keys(graphObject).map((key) => graphObject[key])}
      layout={{
        title: {
          text: "Daily P&L",
          x: 0.025,
          y: 0.95,
          xanchor: "left",
          yanchor: "top",
          font: {
            size: 16,
            color: "#c8c8c8",
          },
        },
        autosize: true,
        plot_bgcolor: "rgba(0, 0, 0, 0)",
        paper_bgcolor: "rgba(0, 0, 0, 0)",
        font: { color: "#c8c8c8" },
        colorway: [
          "#f44336",
          "#e91e63",
          "#3f51b5",
          "#009688",
          "#ffc107",
          "#ff9800",
          "#03a9f4",
        ],
        hoverlabel: {
          bgcolor: "black",
        },
        xaxis: {
          gridcolor: "#3a3a3a",
          tickformat: "%Y-%m-%d",
          rangeslider: { visible: false },
        },
        yaxis: { gridcolor: "#3a3a3a" },
        margin: { t: 40, r: 40, b: 40, l: 40 },
      }}
      style={{ width: "100%", height: 300 }}
      useResizeHandler={true}
    />
  );
};
export default DailyChart;
