import globalSettings from "./settings";
import React, { useState, useEffect } from "react";
import WeatherChart from "./LiveRuns/weatherChart";
import Chart from "./chart";
import { Button, Form } from "react-bootstrap";
import { ArrowClockwise } from "react-bootstrap-icons";

import Spinner from "react-bootstrap/Spinner";

function roundToNextHour() {
  const now = new Date();
  const nextHour = new Date(now);
  nextHour.setHours(nextHour.getHours() + 1);
  nextHour.setMinutes(0);
  nextHour.setSeconds(0);
  nextHour.setMilliseconds(0);

  // Extract components
  const year = nextHour.getFullYear();
  const month = String(nextHour.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(nextHour.getDate()).padStart(2, "0");
  const hours = String(nextHour.getHours()).padStart(2, "0");
  const minutes = "00"; // Rounded to the hour

  // Construct the formatted string
  const roundedDateTimeString = `${year}-${month}-${day} ${hours}:${minutes}`;
  return roundedDateTimeString;
}

const roundedDateTimeString = roundToNextHour();

const LatestImbalanceTab = () => {
  const roundedDateTime = roundToNextHour();
  const [weatherData, setWeatherData] = useState([]);
  const [selectedTick, setSelectedTick] = useState(roundedDateTime);
  const [randomNumber, setRandomNumber] = useState(null);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [chartDataIsLoading, setChartDataIsLoading] = useState(false);

  const fetchData = () => {
    setChartDataIsLoading(true);
    fetch(`${globalSettings.host}/get-latest-weather-imbalance`)
      .then((response) => response.json())
      .then((data) => {
        setWeatherData(data);
        setChartDataIsLoading(false);
      })
      .catch((err) => setChartDataIsLoading(false));
  };

  useEffect(() => {
    fetchData();
    const intervalId = autoRefresh ? setInterval(fetchData, 30000) : null;

    // Clean up function
    return () => {
      clearInterval(intervalId);
    };
  }, [autoRefresh, randomNumber]);

  useEffect(() => {
    const intervalId = autoRefresh
      ? setInterval(() => {
          setRandomNumber((prevNumber) => prevNumber + 1);
        }, 30000)
      : null;

    // Clean up function
    return () => {
      clearInterval(intervalId);
    };
  }, [autoRefresh]);

  const handlePlotClick = (data) => {
    if (data.points.length > 0) {
      const selectedPoint = data.points[0];
      setSelectedTick(selectedPoint.x);
    }
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h5>Overview</h5>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Form.Check
            type="switch"
            id="auto-refresh-switch"
            label="Auto Refresh"
            checked={autoRefresh}
            onChange={() => setAutoRefresh(!autoRefresh)}
            className="me-2"
          />
          <Button
            variant="secondary"
            className="mb-2 ms-2"
            onClick={() => setRandomNumber((prevNumber) => prevNumber + 1)}
          >
            <ArrowClockwise />
          </Button>
        </div>
      </div>

      <div style={{ display: "flex", position: "relative" }}>
        <WeatherChart
          data={weatherData}
          country="nl"
          handlePlotClick={handlePlotClick}
        />
        <WeatherChart
          data={weatherData}
          country="de"
          handlePlotClick={handlePlotClick}
        />

        {chartDataIsLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              zIndex: 9999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only"></span>
            </Spinner>
          </div>
        )}
      </div>
      <h5 className="mt-4">Timeline</h5>
      <p style={{ marginBottom: "0px" }}>Selected hour: {selectedTick}</p>
      <Chart
        deliveryStart={selectedTick}
        jobId={"live"}
        realtime={true}
        refreshChart={randomNumber}
      />
    </div>
  );
};

export default LatestImbalanceTab;
