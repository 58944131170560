import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import globalSettings from "../settings";

function getTimestampDifferenceInMinutes(timestamp1, timestamp2) {
  const differenceInMilliseconds = Math.abs(timestamp1 - timestamp2);
  const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
  return differenceInMinutes.toFixed(2);
}

function LiveRunsTable({ randomNumber, setLatestRun }) {
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${globalSettings.host}/get-jobs?live=1`)
      .then((response) => response.json())
      .then((data) => {
        setJobs(data);
        setIsLoading(false);

        setLatestRun(data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, [randomNumber]);

  const displayedJobs = jobs.slice(0, 10); // Extract the first 10 jobs

  return (
    <div style={{ position: "relative", height: "100%" }}>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="sr-only"></span>
          </Spinner>
        </div>
      )}
      <Table bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Job ID</th>
            <th>Started</th>
            <th>Duration</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {displayedJobs.map((job, index) => {
            const currentTimestamp = job.finished_timestamp
              ? new Date(job.finished_timestamp).getTime()
              : new Date().getTime();

            const jobTimestamp = new Date(job.timestamp).getTime();
            const timeDifference = getTimestampDifferenceInMinutes(
              currentTimestamp,
              jobTimestamp
            );

            const color =
              job.status === "SUCCESS"
                ? "success"
                : job.status === "FAILED"
                ? "danger"
                : "secondary";

            return (
              <tr key={index}>
                <td>{job.job_id}</td>
                <td>{job.timestamp}</td>
                <td>{timeDifference} min</td>
                <td>
                  <Badge pill bg={color} className="badge-top-margin">
                    {job.status}
                  </Badge>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default LiveRunsTable;
