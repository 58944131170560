import React, { useEffect } from "react";
import Plot from "react-plotly.js";
import globalSettings from "./settings";
import Spinner from "react-bootstrap/Spinner";
const borderCountries = ["dk", "no", "gb", "be", "de"];
const borderCountriesColors = {
  nl: "rgba(169,169,169,0.75)",
  dk: "rgba(244,67,54,0.75)",
  no: "rgba(63,81,181,0.75)",
  gb: "rgba(46,125,50,0.75)",
  be: "rgba(255,152,0,0.75)",
  de: "rgba(66,165,245,0.75)",
};

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function calculateCumulativeVolume(data) {
  let cumulativeVolume = 0;
  return data.map((item) => {
    cumulativeVolume += item.volume;
    return { timestamp: item.timestamp, cumulativeVolume: cumulativeVolume };
  });
}

function getNetPosition(data, previousDayNoon) {
  const firstItem = {
    timestamp: previousDayNoon,
    position: 0,
  };

  let cumulativeSum = 0;
  const cumulative = data.map((item) => {
    const value = item.side === "BUY" ? item.quantity : -item.quantity;
    cumulativeSum += value;
    return {
      timestamp: item.timestamp,
      position: cumulativeSum,
    };
  });

  cumulative.unshift(firstItem);

  return cumulative;
}

function getPreviousDayNoon(datetimeString) {
  const date = new Date(datetimeString);
  date.setDate(date.getDate() - 1);
  date.setHours(12, 0, 0, 0);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function calculateEndTimestamp(deliveryStart) {
  const currentDate = new Date();
  const currentTimestamp = currentDate.getTime();
  const deliveryStartTimestamp = new Date(deliveryStart).getTime();
  const endTimestamp =
    deliveryStartTimestamp > currentTimestamp
      ? currentTimestamp
      : deliveryStartTimestamp;
  return endTimestamp;
}

const Chart = ({ deliveryStart, jobId, realtime, refreshChart = null }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [graphObject, setGraphObject] = React.useState({});
  const [maxCapacity, setMaxCapacity] = React.useState(0);
  const previousDayNoon = getPreviousDayNoon(deliveryStart);

  const endTimestamp = calculateEndTimestamp(deliveryStart);

  useEffect(() => {
    setIsLoading(true);
    fetch(
      `${globalSettings.host}/get-signals?delivery_start=${deliveryStart}&job_id=${jobId}`
    )
      .then((response) => response.json())
      .then((data) => {
        const signalsGraph = {
          x: data.map((item) => item.timestamp),
          y: data.map((item) => item.position),
          type: "scatter",
          name: "Signal",
          xaxis: "x",
          yaxis: "y8",
          marker: {
            size: 1,
          },
          line: {
            shape: "hv",
            color: "grey",
            dash: "dot",
          },
          fill: "tozeroy",
          fillcolor: "rgba(128, 128, 128, 0.1)",
        };
        setGraphObject((prev) => ({ ...prev, signalsGraph }));
        setIsLoading(false);
      })
      .catch((er) => setIsLoading(false));
  }, [deliveryStart, jobId, refreshChart]);

  useEffect(() => {
    fetch(
      `${globalSettings.host}/get-trades?delivery_start=${deliveryStart}&job_id=${jobId}`
    )
      .then((response) => response.json())
      .then((data) => {
        const sellTrades = data.filter((item) => item.side === "SELL");
        const buyTrades = data.filter((item) => item.side === "BUY");

        const sellTradesGraph = {
          x: sellTrades.map((item) => item.timestamp),
          y: sellTrades.map((item) => item.price),
          text: sellTrades.map((item) => `${item.quantity} MW`),
          type: "scatter",
          mode: "markers",
          name: "Sell Trades",
          marker: { size: 7.5, symbol: "triangle-up", color: "#1F78B4" },
          xaxis: "x",
          yaxis: "y",
        };

        const buyTradesGraph = {
          x: buyTrades.map((item) => item.timestamp),
          y: buyTrades.map((item) => item.price),
          text: buyTrades.map((item) => `${item.quantity} MW`),
          type: "scatter",
          mode: "markers",
          name: "Buy Trades",
          marker: { size: 7.5, symbol: "triangle-down", color: "#1F78B4" },
          xaxis: "x",
          yaxis: "y",
        };

        const netPositionData = getNetPosition(data, previousDayNoon);
        const netPositionGraph = {
          x: netPositionData.map((item) => item.timestamp),
          y: netPositionData.map((item) => item.position),
          type: "scatter",
          name: "Net Position",
          xaxis: "x",
          yaxis: "y8",
          marker: {
            size: 1,
          },
          line: {
            shape: "hv",
            color: "grey",
          },
          fill: "tozeroy",
          fillcolor: "rgba(128, 128, 128, 0.1)",
        };
        setGraphObject((prev) => ({
          ...prev,
          sellTradesGraph,
          buyTradesGraph,
          netPositionGraph,
        }));
      });
  }, [deliveryStart, jobId, refreshChart]);

  useEffect(() => {
    fetch(
      `${globalSettings.host}/get-chart-data?delivery_start=${deliveryStart}&realtime=${realtime}`
    )
      .then((response) => response.json())
      .then((data) => {
        var maxCapacitySum = 0;
        data.forEach((item) => {
          const totalImportID =
            item.import_be_id +
            item.import_de_id +
            item.import_dk_id +
            item.import_gb_id +
            item.import_no_id;

          const totalImportDA =
            item.import_be_da +
            item.import_de_da +
            item.import_dk_da +
            item.import_gb_da +
            item.import_no_da;

          const totalExportID =
            item.export_be_id +
            item.export_de_id +
            item.export_dk_id +
            item.export_gb_id +
            item.export_no_id;

          const totalExportDA =
            item.export_be_da +
            item.export_de_da +
            item.export_dk_da +
            item.export_gb_da +
            item.export_no_da;

          if (totalImportID >= maxCapacitySum) {
            maxCapacitySum = totalImportID;
          }
          if (totalImportDA >= maxCapacitySum) {
            maxCapacitySum = totalImportDA;
          }

          if (totalExportID >= maxCapacitySum) {
            maxCapacitySum = totalExportID;
          }
          if (totalExportDA >= maxCapacitySum) {
            maxCapacitySum = totalExportDA;
          }
        });

        setMaxCapacity(maxCapacitySum * 1.25);
        const weatherImbalanceGraphDE = {
          x: data.map((item) => item.timestamp),
          y: data.map((item) => item["weather_imbalance_de"]),
          type: "scatter",
          name: `Net. Imbalance DE`,
          xaxis: "x",
          yaxis: "y3",
          legendgroup: "Weather",
          legendgrouptitle: { text: "Weather" },
          marker: {
            color: "#FFA500",
          },
        };

        const weatherImbalanceGraphNL = {
          x: data.map((item) => item.timestamp),
          y: data.map((item) => item["weather_imbalance_nl"]),
          type: "scatter",
          name: `Net. Imbalance NL`,
          fill: "tozeroy",
          xaxis: "x",
          legendgroup: "Weather",
          legendgrouptitle: { text: "Weather" },
          yaxis: "y3",
          marker: {
            color: "#f44336",
          },
        };
        ["solar", "wind"].forEach((imbalance_type) => {
          const weatherImbalancePerTypeGraph = {
            x: data.map((item) => item.timestamp),
            y: data.map((item) => item[`${imbalance_type}_imbalance_nl`]),
            type: "scatter",
            name: `${capitalizeFirstLetter(imbalance_type)} imbalance`,
            xaxis: "x",
            yaxis: "y3",
            legendgroup: "Weather",
            legendgrouptitle: { text: "Weather" },
            visible: "legendonly",
            marker: {
              color: imbalance_type === "solar" ? "#ff9800" : "#1769aa",
            },
          };
          setGraphObject((prev) => ({
            ...prev,
            [`weatherImbalance${imbalance_type}`]: weatherImbalancePerTypeGraph,
          }));
        });

        const spotCountries = borderCountries.concat(["nl"]);
        spotCountries.forEach((country) => {
          const spotPriceGraph = {
            x: data.map((item) => item.timestamp),
            y: data.map((item) => item[`spot_${country}`]),
            type: "scatter",
            name: `Spot Price ${country.toUpperCase()}`,
            xaxis: "x",
            yaxis: "y",
            legendgroup: "Spot prices",
            legendgrouptitle: {
              text: "Spot prices",
            },
            connectgaps: false,
            visible: country === "nl" ? null : "legendonly",
            marker: {
              color: borderCountriesColors[country],
            },
            line: {
              dash: "dot",
            },
          };

          setGraphObject((prev) => ({
            ...prev,
            [`spotPriceGraph${country}`]: spotPriceGraph,
          }));
        });

        const ohlcGraph = {
          x: data.map((item) => item.timestamp),
          close: data.map((item) => item.close_price),
          high: data.map((item) => item.open_price),
          low: data.map((item) => item.low_price),
          open: data.map((item) => item.open_price),
          legendgroup: "Price and volume",
          legendgrouptitle: { text: "Price and volume" },
          name: "OHLC NL",
          type: "candlestick",
          xaxis: "x",
          yaxis: "y",
        };
        const volumeGraph = {
          x: data.map((item) => item.timestamp),
          y: data.map((item) => item.volume),
          type: "bar",
          xaxis: "x",
          yaxis: "y2",
          legendgroup: "Price and volume",
          legendgrouptitle: { text: "Price and volume" },
          name: "Volume",
          marker: { color: "#ffc107" },
        };

        const cumVolArray = calculateCumulativeVolume(data);
        const cumVolumeGraph = {
          x: cumVolArray.map((item) => item.timestamp),
          y: cumVolArray.map((item) => item.cumulativeVolume),
          type: "scatter",
          mode: "line",
          xaxis: "x",
          yaxis: "y2",
          legendgroup: "Price and volume",
          legendgrouptitle: { text: "Price and volume" },
          name: "Cumulative Volume",
          marker: { color: "#ffc107" },
          visible: "legendonly",
        };

        const opregelPrijsGraph = {
          x: data.map((item) => item.timestamp),
          y: data.map((item) => item.hoogste_prijs_opregelen),
          type: "scatter",
          mode: "line",
          xaxis: "x",
          yaxis: "y6",
          legendgroup: "Onbalans",
          legendgrouptitle: { text: "Onbalans" },
          line: { width: 2 },
          name: "Hoogste prijs opregelen",
          marker: { color: "#3F51B5" },
        };

        const midPriceGraph = {
          x: data.map((item) => item.timestamp),
          y: data.map((item) => item.mid_prijs_opregelen),
          type: "scatter",
          mode: "line",
          xaxis: "x",
          yaxis: "y6",
          legendgroup: "Onbalans",
          legendgrouptitle: { text: "Onbalans" },
          line: { width: 2, dash: "dot" },
          name: "Mid opregel prijs.",
          marker: { color: "#FFA500" },
        };

        const afregelPrijsGraph = {
          x: data.map((item) => item.timestamp),
          y: data.map((item) => item.laagste_prijs_afregelen),
          type: "scatter",
          mode: "line",
          xaxis: "x",
          yaxis: "y6",
          legendgroup: "Onbalans",
          legendgrouptitle: { text: "Onbalans" },
          line: { width: 2 },
          name: "Laagste afregel prijs",
          marker: { color: "#f44336" },
        };

        const opregelenMWGraph = {
          x: data.map((item) => item.timestamp),
          y: data.map((item) => item.opregelen),
          type: "scatter",
          mode: "line",
          xaxis: "x",
          yaxis: "y7",
          legendgroup: "Onbalans",
          legendgrouptitle: { text: "Onbalans" },
          line: { width: 2 },
          name: "Op. (MW)",
          marker: { color: "#3F51B5" },
          fill: "tozeroy",
        };

        const opregelenIGCCMWGraph = {
          x: data.map((item) => item.timestamp),
          y: data.map((item) => item.igccbijdrage_op),
          type: "scatter",
          mode: "line",
          xaxis: "x",
          yaxis: "y7",
          line: { width: 2 },
          name: "Op. IGCC (MW)",
          visible: "legendonly",
          legendgroup: "Onbalans IGCC",
          legendgrouptitle: { text: "Onbalans" },
          marker: { color: "green" },
          fill: "tozeroy",
        };
        const afregelenIGCCMWGraph = {
          x: data.map((item) => item.timestamp),
          y: data.map((item) => item.igccbijdrage_af * -1),
          type: "scatter",
          mode: "line",
          xaxis: "x",
          yaxis: "y7",
          line: { width: 2 },
          name: "Af. IGCC (MW)",
          visible: "legendonly",
          legendgroup: "Onbalans IGCC",
          legendgrouptitle: { text: "Onbalans" },
          marker: { color: "yellow" },
          fill: "tozeroy",
        };

        const afregelenMWGraph = {
          x: data.map((item) => item.timestamp),
          y: data.map((item) => item.afregelen * -1),
          type: "scatter",
          mode: "line",
          xaxis: "x",
          yaxis: "y7",
          line: { width: 2 },
          legendgroup: "Onbalans",
          legendgrouptitle: { text: "Onbalans" },
          name: "Af. (MW)",
          marker: { color: "#f44336" },
          fill: "tozeroy",
        };

        borderCountries.forEach((country) => {
          ["import", "export"].forEach((direction) => {
            ["id", "da"].forEach((moment) => {
              const importCapacityGraph = {
                x: data.map((item) => item.timestamp),
                y: data.map(
                  (item) => item[`${direction}_${country}_${moment}`]
                ),
                type: "bar",
                stackgroup: direction,
                legendgroup: direction,
                legendgrouptitle: {
                  text: `${capitalizeFirstLetter(direction)} capacity`,
                },
                xaxis: "x",
                yaxis: direction === "import" ? "y4" : "y5",
                marker: { color: borderCountriesColors[country] },
                line: { width: 0 },
                name: `${capitalizeFirstLetter(
                  direction
                )} ${country.toUpperCase()} ${moment.toUpperCase()}`,
              };
              setGraphObject((prev) => ({
                ...prev,
                [`importCapacityGraph${country}${direction}${moment}`]:
                  importCapacityGraph,
              }));
            });
          });
        });
        setGraphObject((prev) => ({
          ...prev,
          weatherImbalanceGraphDE,
          weatherImbalanceGraphNL,
          ohlcGraph,
          volumeGraph,
          cumVolumeGraph,
          opregelPrijsGraph,
          midPriceGraph,
          afregelPrijsGraph,
          opregelenMWGraph,
          afregelenMWGraph,
          opregelenIGCCMWGraph,
          afregelenIGCCMWGraph,
        }));
      });
  }, [deliveryStart, refreshChart]);

  return (
    <div style={{ position: "relative" }}>
      <Plot
        data={Object.keys(graphObject).map((key) => graphObject[key])}
        layout={{
          autosize: true,
          plot_bgcolor: "rgba(0, 0, 0, 0)",
          paper_bgcolor: "rgba(0, 0, 0, 0)",
          font: { color: "#c8c8c8" },
          hovermode: "x unified",
          barmode: "stack",
          colorway: [
            "#f44336",
            "#e91e63",
            "#3f51b5",
            "#009688",
            "#ffc107",
            "#ff9800",
            "#03a9f4",
          ],
          hoverlabel: {
            bgcolor: "black",
          },
          grid: {
            rows: 8,
            columns: 1,
            subplots: [
              ["xy"],
              ["xy2"],
              ["xy3"],
              ["xy4"],
              ["xy5"],
              ["xy6"],
              ["xy7"],
              ["xy8"],
            ],
            roworder: "top to bottom",
          },
          xaxis: {
            gridcolor: "#3a3a3a",
            tickformat: "%m-%d %H:%M",
            rangeslider: { visible: false },
            range: [previousDayNoon, endTimestamp],
            spikemode: "across",
            spikecolor: "black",
            spikethickness: 0.1,
            spikedash: "solid",
          },
          yaxis: { gridcolor: "#3a3a3a", domain: [0.8, 1] },
          yaxis2: { gridcolor: "#3a3a3a", domain: [0.7, 0.8] },
          yaxis3: { gridcolor: "#3a3a3a", domain: [0.5, 0.7] },
          yaxis4: {
            gridcolor: "#3a3a3a",
            domain: [0.4, 0.5],
            range: [0, maxCapacity],
          },
          yaxis5: {
            gridcolor: "#3a3a3a",
            domain: [0.3, 0.4],
            range: [maxCapacity, 0],
          },
          yaxis6: { gridcolor: "#3a3a3a", domain: [0.2, 0.3] },
          yaxis7: { gridcolor: "#3a3a3a", domain: [0.1, 0.2] },
          yaxis8: { gridcolor: "#3a3a3a", domain: [0, 0.1] },
          margin: { t: 20, r: 40, b: 40, l: 30 },
        }}
        style={{ width: "100%", height: 1200 }}
        useResizeHandler={true}
      />
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="sr-only"></span>
          </Spinner>
        </div>
      )}
    </div>
  );
};
export default Chart;
