import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import React from "react";

const validateForm = (parameterValues) => {
  if (
    (parameterValues.period_start || parameterValues.deliveryStart) &&
    (parameterValues.period_end || parameterValues.deliveryStart) &&
    parameterValues.absolute_change_threshold &&
    parameterValues.hourly_change_threshold &&
    parameterValues.max_position &&
    parameterValues.unit_size &&
    parameterValues.interconnection_capacity !== "" &&
    parameterValues.interconnection_capacity !== undefined &&
    parameterValues.time_before_close
  ) {
    return true;
  } else {
    return false;
  }
};

function BacktestParameterForm({
  setParameterValues,
  parameterValues,
  includeDateParams = true,
}) {
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
    .toISOString()
    .split("T")[0];

  function handleParameterInputChange(paramName, value) {
    let processedValue;
    if (
      paramName === "period_start" ||
      paramName === "period_end" ||
      paramName === "interconnection_capacity" ||
      paramName === "trailing" ||
      paramName === "allow_reopen_after_closure"
    ) {
      processedValue = value;
    } else {
      processedValue = Number(value.replace(/[^0-9]/g, ""));
    }

    setParameterValues({
      ...parameterValues,
      [paramName]: processedValue,
    });
  }

  return (
    <div>
      {includeDateParams ? (
        <div>
          <Form.Group as={Row} className="mb-2" key="period_start">
            <Form.Label column>Period start</Form.Label>
            <Col sm="5">
              <Form.Control
                type="date"
                value={parameterValues.period_start || ""}
                onChange={(event) =>
                  handleParameterInputChange("period_start", event.target.value)
                }
                min="2021-01-01"
                max={yesterday}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" key="period_end">
            <Form.Label column>Period end</Form.Label>
            <Col sm="5">
              <Form.Control
                type="date"
                value={parameterValues.period_end || ""}
                onChange={(event) =>
                  handleParameterInputChange("period_end", event.target.value)
                }
                min="2021-01-01"
                max={yesterday}
              />
            </Col>
          </Form.Group>
        </div>
      ) : null}
      <Form.Group as={Row} className="mb-2" key="absolute_change_threshold">
        <Form.Label column>Absolute Change Threshold</Form.Label>
        <Col sm="5">
          <Form.Control
            type="text"
            value={parameterValues.absolute_change_threshold || ""}
            onChange={(event) =>
              handleParameterInputChange(
                "absolute_change_threshold",
                event.target.value
              )
            }
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2" key="hourly_change_threshold">
        <Form.Label column>Hourly Change Threshold</Form.Label>
        <Col sm="5">
          <Form.Control
            type="text"
            pattern="[0-9]*"
            value={parameterValues.hourly_change_threshold || ""}
            onChange={(event) =>
              handleParameterInputChange(
                "hourly_change_threshold",
                event.target.value
              )
            }
          />
        </Col>
      </Form.Group>

      {/* <Form.Group as={Row} className="mb-2" key="de_weather_imbalance_factor">
        <Form.Label column>
          DE Weather Imbalance Factor{" "}
          <Information
            info={
              "The percentage of the DE imbalance to include. For example: when NL imbalance is 100 and the DE imbalance is -100, with a factor of 100%, the total imbalance is 0"
            }
          />
        </Form.Label>
        <Col sm="5">
          <InputGroup>
            <Form.Control
              type="text"
              pattern="[0-9]*"
              value={parameterValues.de_weather_imbalance_factor || ""}
              onChange={(event) =>
                handleParameterInputChange(
                  "de_weather_imbalance_factor",
                  event.target.value
                )
              }
            />
            <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
          </InputGroup>
        </Col>
      </Form.Group> */}

      <Form.Group as={Row} className="mb-2" key="max_position">
        <Form.Label column>Max Position</Form.Label>
        <Col sm="5">
          <InputGroup>
            <Form.Control
              type="text"
              value={parameterValues.max_position || ""}
              onChange={(event) =>
                handleParameterInputChange("max_position", event.target.value)
              }
            />
            <InputGroup.Text id="basic-addon2">MW</InputGroup.Text>
          </InputGroup>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2" key="unit_size">
        <Form.Label column>Unit Size</Form.Label>
        <Col sm="5">
          <Form.Control
            type="text"
            value={parameterValues.unit_size || ""}
            onChange={(event) =>
              handleParameterInputChange("unit_size", event.target.value)
            }
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-2" key="interconnection_capacity">
        <Form.Label column>Interconnection Capacity</Form.Label>
        <Col sm="5">
          <Form.Select
            aria-label="Default select example"
            value={parameterValues.interconnection_capacity}
            onChange={(event) =>
              handleParameterInputChange(
                "interconnection_capacity",
                event.target.value
              )
            }
          >
            <option value=""></option>
            <option value="True">True</option>
            <option value="False">False</option>
          </Form.Select>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-2" key="time_before_close">
        <Form.Label column>Time before close</Form.Label>
        <Col sm="5">
          <InputGroup>
            <Form.Control
              type="text"
              value={parameterValues.time_before_close || ""}
              onChange={(event) =>
                handleParameterInputChange(
                  "time_before_close",
                  event.target.value
                )
              }
            />
            <InputGroup.Text id="basic-addon2">min</InputGroup.Text>
          </InputGroup>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2" key="spot_price_delta">
        <Form.Label column>Price spot delta</Form.Label>
        <Col sm="5">
          <InputGroup>
            <Form.Control
              type="text"
              value={parameterValues.spot_price_delta || ""}
              onChange={(event) =>
                handleParameterInputChange(
                  "spot_price_delta",
                  event.target.value
                )
              }
            />
            <InputGroup.Text id="basic-addon2">€ / MWh</InputGroup.Text>
          </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-2" key="stop_loss_total">
        <Form.Label column>Stop-loss total</Form.Label>
        <Col sm="5">
          <InputGroup>
            <Form.Control
              type="text"
              value={parameterValues.stop_loss_total || ""}
              onChange={(event) =>
                handleParameterInputChange(
                  "stop_loss_total",
                  event.target.value
                )
              }
            />
            <InputGroup.Text id="basic-addon2">€</InputGroup.Text>
          </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-2" key="stop_loss_price">
        <Form.Label column>Stop-loss price</Form.Label>
        <Col sm="5">
          <InputGroup>
            <Form.Control
              type="text"
              value={parameterValues.stop_loss_price || ""}
              onChange={(event) =>
                handleParameterInputChange(
                  "stop_loss_price",
                  event.target.value
                )
              }
            />
            <InputGroup.Text id="basic-addon2">€ / MWh</InputGroup.Text>
          </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-2" key="trailing">
        <Form.Label column>Trailing</Form.Label>
        <Col sm="5">
          <Form.Select
            aria-label="Default select example"
            value={parameterValues.trailing}
            onChange={(event) =>
              handleParameterInputChange("trailing", event.target.value)
            }
          >
            <option value=""></option>
            <option value="True">True</option>
            <option value="False">False</option>
          </Form.Select>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-2" key="take_profit">
        <Form.Label column>Take profit</Form.Label>
        <Col sm="5">
          <InputGroup>
            <Form.Control
              type="text"
              value={parameterValues.take_profit || ""}
              onChange={(event) =>
                handleParameterInputChange("take_profit", event.target.value)
              }
            />
            <InputGroup.Text id="basic-addon2">€ / MWh</InputGroup.Text>
          </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-2" key="allow_reopen_after_closure">
        <Form.Label column>Allow re-open after closure</Form.Label>
        <Col sm="5">
          <InputGroup>
            <Form.Check
              type="switch"
              id="allow_reopen_after_closure"
              label=""
              checked={parameterValues.allow_reopen_after_closure}
              onChange={(event) =>
                handleParameterInputChange(
                  "allow_reopen_after_closure",
                  event.target.checked
                )
              }
            />
          </InputGroup>
        </Col>
      </Form.Group>
    </div>
  );
}

export { BacktestParameterForm, validateForm };
