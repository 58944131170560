import { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { InfoCircle } from "react-bootstrap-icons";
function Information({ info }) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <InfoCircle
        style={{ width: 12 }}
        className="ms-1"
        ref={target}
        role="button"
        onClick={() => setShow(!show)}
      ></InfoCircle>
      <Overlay target={target.current} show={show} placement="right">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            {info}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
}

export default Information;
