import React from "react";
import NewJobModal from "./JobModal";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import JobRunTable from "./Table";
import SettingsTable from "./settingsTable";
import { useState, useEffect } from "react";
import Chart from "./chart";
import DeliveryStatsTable from "./deliveryStats";
import { Button } from "react-bootstrap";
import { Trash, ArrowClockwise } from "react-bootstrap-icons";
import globalSettings from "./settings.js";
import Accordion from "react-bootstrap/Accordion";
import SingleContractTab from "./singleContractTab.js";
import { JsonToExcel } from "react-json-to-excel";
import DailyChart from "./dailyChart.js";
import LatestImbalanceTab from "./LatestImbalanceTab.js";
import ExportTab from "./exportTab.js";
import NotificationsTab from "./LiveRuns/LiveRunsTab.js";

function App() {
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedDeliveryStart, setSelectedDeliveryStart] = useState(null);
  const [randomNumber, setRandomNumber] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [backtestSettings, setBacktestSettings] = useState([]);
  const [testSettingsTabClicked, setTestSettingsTabClicked] = useState(false);
  const [realtimeTabClicked, setRealtimeTabClicked] = useState(false);
  const [resultsData, setResultsData] = useState([]);
  const [deliveryStatsData, setDeliveryStatsData] = useState([]);

  useEffect(() => {
    fetch(`${globalSettings.host}/get-jobs?live=0`)
      .then((response) => response.json())
      .then((data) => setJobs(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, [randomNumber]);

  useEffect(() => {
    fetch(`${globalSettings.host}/get-results-per-job`)
      .then((response) => response.json())
      .then((data) => setResultsData(data))
      .catch((error) => console.error("Error:", error));
  }, [randomNumber]);

  useEffect(() => {
    fetch(
      `${globalSettings.host}/get-results-per-delivery_hour?job_id=${selectedJob}`
    )
      .then((response) => response.json())
      .then((data) => setDeliveryStatsData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, [selectedJob]);

  const deleteAllJobs = () => {
    fetch(`${globalSettings.host}/delete-all-jobs`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setRandomNumber((prevNumber) => prevNumber + 1);
      })
      .catch((error) => {
        alert("Error when deleting jobs.");
      });
  };

  return (
    <div>
      <Navbar className="bg-body-tertiary">
        <Container
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Navbar.Brand style={{ fontSize: "24px" }}>
            Weather Algorithm Backtester ⛅
          </Navbar.Brand>
        </Container>
      </Navbar>
      <br />
      <div className="container">
        <Tabs
          defaultActiveKey="jobs"
          id="uncontrolled-tab-example"
          className="mb-3 mt-3"
          transition={false}
          onSelect={(key) => {
            if (key === "playground") {
              setTestSettingsTabClicked(true);
            } else if (key === "realtime") {
              setRealtimeTabClicked(true);
            }
          }}
        >
          <Tab eventKey="jobs" title="Jobs">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {jobs.length === 0 ? (
                <p>Click on 'New job' to run the first backtest job</p>
              ) : (
                <p>All backtest jobs are listed in the table below.</p>
              )}

              <div style={{ display: "flex" }}>
                <NewJobModal
                  className="ms-2"
                  backtestSettings={backtestSettings}
                  jobsCount={jobs.length}
                />
                <Button
                  variant="secondary"
                  className="mb-2 ms-2"
                  onClick={() =>
                    setRandomNumber((prevNumber) => prevNumber + 1)
                  }
                >
                  <ArrowClockwise />
                </Button>
                <Button
                  variant="secondary"
                  // size="sm"
                  className="ms-2 mb-2"
                  onClick={deleteAllJobs}
                >
                  <Trash size={14} />
                </Button>
              </div>
            </div>
            {jobs.length === 0 ? null : (
              <JobRunTable jobs={jobs} setRandomNumber={setRandomNumber} />
            )}
          </Tab>
          <Tab eventKey="chart" title="Job Results">
            <div>
              <Accordion defaultActiveKey="0" alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {selectedJob ? (
                      <div>
                        <b className="me-3">Settings: </b>
                        {selectedJob}
                      </div>
                    ) : (
                      <b className="me-3">Settings</b>
                    )}
                  </Accordion.Header>
                  <Accordion.Body>
                    <SettingsTable
                      onRowSelect={setSelectedJob}
                      resultsData={resultsData}
                    />
                    {selectedJob && <DailyChart jobId={selectedJob} />}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    {selectedDeliveryStart ? (
                      <div>
                        <b className="me-3">Delivery Hour: </b>
                        {selectedDeliveryStart.delivery_start}
                      </div>
                    ) : (
                      <b className="me-3">Delivery Hour </b>
                    )}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      {selectedJob && (
                        <JsonToExcel
                          title="Export data"
                          data={deliveryStatsData}
                          fileName="backtest_results"
                          btnClassName="btn btn-primary bg-secondary mb-3"
                        />
                      )}
                    </div>
                    {selectedJob ? (
                      <DeliveryStatsTable
                        selectedDeliveryStart={
                          selectedDeliveryStart &&
                          selectedDeliveryStart.delivery_start
                        }
                        setSelectedDeliveryStart={setSelectedDeliveryStart}
                        deliveryStatsData={deliveryStatsData}
                      />
                    ) : (
                      <p>Select settings first</p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              {selectedDeliveryStart && selectedJob ? (
                <Chart
                  deliveryStart={selectedDeliveryStart.delivery_start}
                  jobId={selectedJob}
                />
              ) : null}
            </div>
          </Tab>
          <Tab eventKey="playground" title="Test Parameters">
            <SingleContractTab tabClicked={testSettingsTabClicked} />
          </Tab>

          <Tab eventKey="export" title="Export">
            <ExportTab />
          </Tab>
          <Tab eventKey="notifications" title="Realtime">
            <NotificationsTab />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default App;
