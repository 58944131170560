const SingleContractBacktestResults = ({ backtestResultsData }) => {
  return (
    <div style={{ display: "flex" }} className="mb-0">
      <p className="me-5 ms-3" style={{ display: "flex" }}>
        <b className="me-2">Profit Net. Loss: </b>
        <p
          className={
            backtestResultsData.imbalance !== 0
              ? "text-warning"
              : backtestResultsData.net_profit === 0
              ? ""
              : backtestResultsData.net_profit > 0
              ? "text-success"
              : "text-danger"
          }
        >
          € {backtestResultsData.net_profit}
        </p>
      </p>
      <p className={"me-5"}>
        <b className="me-2">Imbalance: </b>
        {backtestResultsData.imbalance} MW
      </p>
      <p className="me-5">
        <b className="me-2">VWAP Buy: </b>€ {backtestResultsData.vwap_buy}
      </p>
      <p className="me-5">
        <b className="me-2">VWAP Sell: </b>€ {backtestResultsData.vwap_sell}
      </p>
    </div>
  );
};

export default SingleContractBacktestResults;
