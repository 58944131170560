import Table from "react-bootstrap/Table";
import ProgressBar from "react-bootstrap/ProgressBar";
import Badge from "react-bootstrap/Badge";
import ShowSettings from "./showSettings";
import { Trash } from "react-bootstrap-icons";
import globalSettings from "./settings";

function getTimestampDifferenceInMinutes(timestamp1, timestamp2) {
  const differenceInMilliseconds = Math.abs(timestamp1 - timestamp2);
  const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
  return differenceInMinutes.toFixed(2);
}

function JobRunTable({ jobs, setRandomNumber }) {
  const handleJobDelete = (jobId) => {
    fetch(`${globalSettings.host}/delete-job?job_id=${jobId}`)
      .then((response) => response.json())
      .then((data) => {
        alert(`${jobId} deleted`);
        setRandomNumber((prev) => prev + 1);
      })
      .catch((error) => alert(`Errer when deleting ${jobId}`));
  };

  return (
    <div style={{ height: "800px", overflow: "auto" }}>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Job ID</th>
            <th>Started</th>
            <th>Duration</th>
            <th>Days</th>
            <th>Status</th>
            <th>Progress</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {jobs.map((job, index) => {
            const progress = Math.round(
              (job.finished_days / job.total_days) * 100
            );

            const currentTimestamp = job.finished_timestamp
              ? new Date(job.finished_timestamp).getTime()
              : new Date().getTime();

            const jobTimestamp = new Date(job.timestamp).getTime();
            const timeDifference = getTimestampDifferenceInMinutes(
              currentTimestamp,
              jobTimestamp
            );

            const color =
              job.status === "SUCCESS"
                ? "success"
                : job.status === "FAILED"
                ? "danger"
                : "secondary";

            return (
              <tr key={index}>
                <td>{job.job_id}</td>
                <td>{job.timestamp}</td>
                <td>{timeDifference} min</td>
                <td>{job.total_days}</td>
                <td>
                  <Badge pill bg={color} className="badge-top-margin">
                    {job.status}
                  </Badge>
                </td>
                <td>
                  <ProgressBar
                    style={{ marginTop: "3px", height: "100%" }}
                    now={progress}
                    label={`${progress}%`}
                    variant={color}
                  />
                </td>
                <td>
                  <ShowSettings jobId={job.job_id} />
                </td>
                <td>
                  <Trash
                    role="button"
                    onClick={() => handleJobDelete(job.job_id)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default JobRunTable;
